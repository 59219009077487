/*global gettext */
/*eslint no-undef: "error"*/

import React from "react";
import { connect } from 'react-redux';
var $ = require( "jquery" );

class CountdownTimer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount(){
    $('.timer').circularCountDown({
      size: 60,
      borderSize: 0,
      colorCircle:'white',
      background:'#E9D16F',
      fontFamily:'sans-serif',
      fontColor:'#E9D16F',
      fontSize: 16,
      // delayToFadeIn: 0,
      // delayToFadeOut: 0,
      // reverseLoading:false,
      // reverseRotation:false,
      duration: {
          hours: 0,
          minutes: 0,
          seconds: parseInt(this.props.timerSeconds)
      },
      beforeStart:function(){},
      end:function(){}
    });
  }

  render() {
    const timerSeconds = parseInt(this.props.timerSeconds);

    return (
      <div>
        <div className="text">
          <p className="title">{this.props.title}</p>
          <p>{gettext('verður aðgengileg eftir')} {Math.floor(timerSeconds / 3600)} {gettext('klukkutíma')}</p>
        </div>
        <div className="timer">
          <div className="timer-wrapper"></div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {

    var f = {
        exams: {},
    }
    f.exams = (state.exams === undefined) ? {} : state.exams
    return f;
}

export default connect(mapStateToProps)(CountdownTimer);
