/*global gettext,Raven */
/*eslint no-undef: "error"*/

import React from 'react';
import { connect } from 'react-redux';
// import accounting from 'accounting'

export function parseJSON(response) {
  return response.json();
}

export const handleNetworkError = (error) => {
  if (window.Raven !== undefined) {
    Raven.captureException(error)
  } else {
    throw (error)
  }
}

export const Spinner = (props) => <i className="spinner"></i>;


export const connectWithStore = (store, WrappedComponent, ...args) => {
  var ConnectedWrappedComponent = connect(...args)(WrappedComponent)
  return function connectedWrappedComponent(props) {
    return <ConnectedWrappedComponent {...props} store={store} />
  }
}

export const formatKennitala = (value) => {
  return `${value.substring(0, 6)}-${value.substring(6, 10)}`
}

export const getUrlVars = () => {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}


// export const formatMoney = (num) => {
//   return accounting.formatMoney(+num, '', 2, ".", ",")
// }

export const getUrlParam = (parameter, defaultvalue) => {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
}

export const getPrettierDateFormat = (date) => {
  let prettyDate = "";
  let splitDate = date.split("/")
  let monthName = "";
  if (splitDate[1] == 1) {
    monthName = gettext("janúar")
  } else if (splitDate[1] == 2) {
    monthName = gettext("febrúar")
  } else if (splitDate[1] == 3) {
    monthName = gettext("mars")
  } else if (splitDate[1] == 4) {
    monthName = gettext("apríl")
  } else if (splitDate[1] == 5) {
    monthName = gettext("maí")
  } else if (splitDate[1] == 6) {
    monthName = gettext("júní")
  } else if (splitDate[1] == 7) {
    monthName = gettext("júlí")
  } else if (splitDate[1] == 8) {
    monthName = gettext("ágúst")
  } else if (splitDate[1] == 9) {
    monthName = gettext("september")
  } else if (splitDate[1] == 10) {
    monthName = gettext("október")
  } else if (splitDate[1] == 11) {
    monthName = gettext("nóvember")
  } else {
    monthName = gettext("desember")
  }

  let day = "";
  if (splitDate[0].startsWith("0")) {
    day = splitDate[0].slice(1)
  } else {
    day = splitDate[0]
  }
  prettyDate = day + ". " + monthName;
  return prettyDate
}

export const getWeekDay = (date) => {
  let splitDate = date.split("/")
  let day = new Date(splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0] + "T00:00:00Z").getDay();
  let dayName = "";
  if (day == 0) {
    dayName = gettext("sunnudaginn")
  } else if (day == 1) {
    dayName = gettext("mánudaginn")
  } else if (day == 2) {
    dayName = gettext("þriðjudaginn")
  } else if (day == 3) {
    dayName = gettext("miðvikudaginn")
  } else if (day == 4) {
    dayName = gettext("fimmtudaginn")
  } else if (day == 5) {
    dayName = gettext("föstudaginn")
  } else if (day == 6) {
    dayName = gettext("laugardaginn")
  }
  return dayName;
}

export const currencyFormatter = (amount) => {
  let formattedAmount = amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

  return formattedAmount
}