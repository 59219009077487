/*global gettext */
/*eslint no-undef: "error"*/

import React from 'react';


class ResultOverview extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    openAllResults(){
        this.props.toggleAllResultsActive();
    }
    
    roundUpGrade() {
        return (
            Math.round(this.props.responses.grade * 10) / 10
        )
    }

    displayRereadOrContinueButton(responses){
        if(responses.grade >= responses.min_grade) {
            return (
                <a className="button onward" href={this.props.nextPageLink}>{gettext('Halda áfram')}</a>
            )
        } else {
            return(
                <a className="button re-read" href={this.props.readAgainLink}>{gettext('Lesa kaflann aftur')}</a>
            )
        }
    }

    render() {
        let responses = this.props.responses;
        return (
            <div className="results-overview">
                <div className="results-wrapper">
                    <div className="grade-percentage">
                        <div className="min-grade" style={{height: responses.min_grade * 10+'%'}}></div>
                        <div className="grade" style={{height: responses.grade * 10+'%'}}></div>
                    </div>

                    <div className="grades">
                        <div>{gettext('Einkunn')} <span className="grade-number">{this.roundUpGrade()}</span></div>
                        <div>{gettext('Lágmarkseinkunn')} {responses.min_grade}</div>
                    </div>

                    <button className="button go-over" onClick={this.openAllResults.bind(this)}>{gettext('Fara yfir verkefnið')}</button>
                    <a className="button re-do" href={window.location.href}>{gettext('Endurtaka verkefnið')}</a>
                    {this.displayRereadOrContinueButton(responses)}
                    
                </div>
            </div>
        )
    }
}

ResultOverview.defaultProps = {
}


export default ResultOverview;