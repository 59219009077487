import React from 'react'
import ReactDOM from 'react-dom/client'
import ExamPage from './exampage'
import { store } from '../../store' 
var $ = require( "jquery" );

$.fn.exampage = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    const root = ReactDOM.createRoot(this);
    root.render(React.createElement(ExamPage, options));
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-exam-page]').exampage();
});
