import { answerTypes } from './actions'

const initialState = {
    isFetching: false,
    answerList: [],
}



export function answers(state = initialState, action) {
    function addOrReplace(array, item) { 
        const i = array.findIndex(_item => _item.id === item.id);
        if (i > -1) array[i] = item;
        else array.push(item);
    }
    

    if (action.type == answerTypes.ADD_ANSWER) {
        state = { ...state }
        addOrReplace(state.answerList, {id: action.data.key, value: action.data.value})           
    }
    return state;
}