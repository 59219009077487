import { createTypes } from 'redux-action-creator';

export const answerTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'ADD_ANSWER'], 'ANSWERS');


export const addAnswer = (answer) => {
    return {
        type: answerTypes.ADD_ANSWER,
        data: answer
    }
}