import { examTypes } from './actions'
import { submitAnswersTypes } from './actions'

const initialState = {
    isFetching: false,
    results: [],
    submitResponse: ""
}



export function exams(state = initialState, action) {


    switch (action.type) {

        case examTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            
            return state


        case examTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.results = action.data.questions
            state.extraInformation = {
                'description': action.data.description,
                'read_again_link': action.data.read_again_link,
                'title': action.data.title,
                'next_page_link': action.data.next_page_link
            }

            return state

        case submitAnswersTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            
            return state


        case submitAnswersTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.submitResponse = action.data

            return state

        default:
            return state
    }
}