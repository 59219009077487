import React from 'react'
import ReactDOM from 'react-dom'
import CountdownTimer from './countdown-timer'
import { store } from '../../store' 
var $ = require( "jquery" );

$.fn.countdownTimer = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    const root = ReactDOM.createRoot(this);
    root.render(React.createElement(CountdownTimer, options));
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-countdown-timer]').countdownTimer();
});
